import axios from "axios";
 
export const getResponse = async (prompt) => {
    const response = await axios.post(process.env.REACT_APP_BACKEND_URL+"chatgpt", {prompt});
    return response.data.result;
  }

export const sendEmail = async (formData) => {
  const response = await axios.post(process.env.REACT_APP_BACKEND_URL+"contact", formData);
  return response;
}