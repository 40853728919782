import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import logo from "../../images/logo.png";
import { useAdblockDetector } from 'react-adblock-detector';
import AdblockModal from "../AdblockModal";

const countries = [
  { name: "English", code: "english", flag: "https://flagcdn.com/16x12/gb.png" },
  { name: "Arabic", code: "arabic", flag: "https://flagcdn.com/16x12/sa.png" },
  { name: "French", code: "french", flag: "https://flagcdn.com/16x12/fr.png" },
  { name: "Deutsch", code: "deutsch", flag: "https://flagcdn.com/16x12/de.png" },
  { name: "Italian", code: "italian", flag: "https://flagcdn.com/16x12/it.png" },
  { name: "Spanish", code: "spanish", flag: "https://flagcdn.com/16x12/es.png" },
  { name: "Portuguese", code: "portuguese", flag: "https://flagcdn.com/16x12/pt.png" },
  { name: "Russian", code: "russian", flag: "https://flagcdn.com/16x12/ru.png" },
  { name: "Korean", code: "korean", flag: "https://flagcdn.com/16x12/kr.png" },
  { name: "Chinese", code: "chinese", flag: "https://flagcdn.com/16x12/cn.png" },
  { name: "Japanese", code: "japanese", flag: "https://flagcdn.com/16x12/jp.png" }
];
const Header = () => {
  const [isAdblockCheckComplete, isAdblockerDetected] = useAdblockDetector();
  const [openModal, setOpenModal] = useState(false);

  const { t, i18n } = useTranslation();
  function getFlag(countries, selectedCountry) {
    const country = countries.find(function (country) {
      return country.name === selectedCountry;
    });

    return country ? country.flag : undefined;
  }
  const [selectedCountry, setSelectedCountry] = useState({ name: i18n.language, flag: getFlag(countries, i18n.language.charAt(0).toUpperCase() + i18n.language.slice(1)) });
  const [menuOpen, setMenuOpen] = useState(false);
  const [language, setLanguage] = useState(i18n.language);
  const [isClicked, setIsClicked] = useState(false);

  useEffect(() => {
    if (i18n.language === "en-US") {
      setSelectedCountry({ name: "English", flag: getFlag(countries, "English") })
    } else if (i18n.language === "it-IT") {
      i18n.changeLanguage("italian");
      setSelectedCountry({ name: "Italian", flag: getFlag(countries, "Italian") })
    } else if (i18n.language === "ru-RU") {
      i18n.changeLanguage("russian");
      setSelectedCountry({ name: "Russian", flag: getFlag(countries, "Russian") })
    } else if (i18n.language === "cn-CN") {
      i18n.changeLanguage("chinese");
      setSelectedCountry({ name: "Chinese", flag: getFlag(countries, "Chinese") })
    } else if (i18n.language === "pt-PT") {
      i18n.changeLanguage("portuguese");
      setSelectedCountry({ name: "Portuguese", flag: getFlag(countries, "Portuguese") })
    } else if (i18n.language === "de-DE") {
      i18n.changeLanguage("deutsch");
      setSelectedCountry({ name: "Deutsch", flag: getFlag(countries, "Deutsch") })
    } else if (i18n.language === "es-ES") {
      i18n.changeLanguage("spanish");
      setSelectedCountry({ name: "Spanish", flag: getFlag(countries, "Spanish") })
    }
  }, [i18n, i18n.language])

  useEffect(() => {
    if (isAdblockerDetected) {
      setOpenModal(true);
    } else {
      setOpenModal(false)
    }
  }, [isAdblockerDetected])

  const handleClick = (code, name) => {
    setLanguage(code)
    i18n.changeLanguage(code);
    setSelectedCountry({ name: name, flag: getFlag(countries, name) });
    setIsClicked(false);
  };

  return (
    <>
      <AdblockModal openModal={openModal} />
      <header className="bg-black">
        <nav className="font-nunito container mx-auto flex justify-between py-4 relative">
          <div>
            <Link to="/" id="logo">
              <img className="logo" src={logo} alt="TheHomeWork AI logo" />
            </Link>
            {/* bg-black text-white text-sm outline-0 block p-2.5 */}
            <div className="flag-dropdown">
              <div className="selected-item" onClick={() => setIsClicked(!isClicked)}>
                <img className="mr-3" src={`${selectedCountry.flag}`} alt={selectedCountry.name} />
                <span>{selectedCountry.name}</span>
                <i className={`ml-8 fas fa-caret-${isClicked ? 'up' : 'down'}`}></i>
              </div>
              {isClicked && (
                <ul className="dropdown-list">
                  {countries.map((country) => (
                    <li key={country.name} className="hover:bg-gray-800 mb-2" onClick={() => handleClick(country.code, country.name)}>
                      <img src={`${country.flag}`} alt={country.name} />
                      <span>{country.name}</span>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
          <div className="flex lg:mt-0 mt-6">
            <a href="https://bit.ly/TheHomeworkAI" rel="noreferrer" target="_blank" className="flex items-center h-[70px] mt-4 rounded-full bg-cyan-500 text-zinc-100 px-8 py-4 hover:bg-cyan-300">
              {t('hero.download_btn')}
              <img className="ml-2 w-[25px]" src="/images/playstore.png" alt="play store icon" />
              <img className="ml-2 w-[25px]" src="/images/appStore.png" alt="app store icon" />
            </a>
          </div>
          <div className={`lg:flex ${menuOpen ? "absolute px-4 bg-black w-full pb-2 top-20" : "hidden"} text-lg lg:justify-center lg:items-center font-semibold`}>
            <Link to="/" className="block text-white text-lg lg:inline-block lg:mt-0 mr-5">{t("menu.home")}</Link>
            <Link to="/about-us" className="block text-white text-lg lg:inline-block lg:mt-0 mr-5">{t("menu.about")}</Link>
            <Link to="/contact" className="block text-white text-lg lg:inline-block lg:mt-0 mr-5">{t("menu.contact")}</Link>

           {/* <div className="lg:hidden flex">
              <a href="https://bit.ly/TheHomeworkAI" rel="noreferrer" target="_blank" className="flex items-center lg:h-[70px] h-[50px] text-sm lg:text-md mt-4 rounded-full bg-cyan-500 text-zinc-100 px-8 py-4 hover:bg-cyan-300">
                {t('hero.download_btn')}
                <img className="ml-2 w-[25px]" src="/images/playstore.png" alt="play store icon" />
                <img className="ml-2 w-[25px]" src="/images/appStore.png" alt="app store icon" />
              </a>
            </div> */}
            {/* <Link to="/blogs" className="block text-white text-lg lg:inline-block lg:mt-0 mr-5">{t("menu.blogs")}</Link> */}
          </div>
          <span onClick={() => setMenuOpen(!menuOpen)} className={`lg:hidden absolute right-4 top-4 text-white font-semibold text-lg`}><i className="fa-solid fa-bars"></i></span>
        </nav>
      </header>
    </>
  )
}
export default Header
