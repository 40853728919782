import Button from "./Layouts/Button";
import Content from "./Content";
import {getResponse} from "../services/openaiService";
import { forwardRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import PropTypes from 'prop-types';


const GeneralForm = forwardRef(({pageTitle, label2, type, maxLength, minLength}, generalFormRef) => {
  
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [formData, setFormData] = useState({
    message:"",
    loading:false,
    generatedText:null
  });
  const {message, generatedText, loading} = formData;

  const handleChange = (e) => {
    setFormData({...formData, [e.target.name]:e.target.value});
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormData({...formData, loading:true});
    let prompt;
    if(type === "MATHEMATICS"){
        prompt = `Please solve this math problem (${message}). Everything should be in [${currentLanguage}], and provide a step-by-step explanation of your work.`
    } else if(type === "BOOK_RESUME"){
        prompt = `Please provide a summary of the plot, main characters, and themes of the book [${message}]. Everything should be in [${currentLanguage}].`
    } else if(type === "SCIENCE"){
        prompt = `Please explain the scientific concept or theory behind (${message}) and provide examples or applications of it in real-world scenarios. Everything should be in [${currentLanguage}].`
    } else if(type === "ART"){
        prompt = `Please explain the meaning and significance of (${message}) and how it relates to the broader context of art history. Everything should be in [${currentLanguage}].`
    } else if(type === "PHYSICS"){
        prompt = `Answer the following physics-related question in a clear and detailed manner, including explanations and steps taken to reach the solution: [${message}]. Everything should be in [${currentLanguage}]".`
    } else if(type === "HISTORY"){
        prompt = `Answer the following history-related question in a comprehensive manner, including relevant background information and explanations: [${message}]. Everything should be in [${currentLanguage}].`
    } else if(type === "LITERATURE"){
        prompt = `Answer the following literature-related question in a concise and relevant manner:[${message}]. Everything should be in [${currentLanguage}].`
    } else if(type === "GENERAL_WILLING"){
        prompt = `Answer the following well-defined question in a comprehensive manner, including relevant background information and explanations: [${message}]. Everything should be in [${currentLanguage}].`
    } else if(type === "ESSAY"){
        prompt = `Write a 4 paragraphs argumentative essay on the topic of [${message}]. In your essay, provide clear evidence and analysis to support your thesis statement, which should argue a specific viewpoint on the issue. Everything should be in [${currentLanguage}] and without plagiarism.`
    } 
    const result = await getResponse(prompt);
    setFormData({...formData, generatedText:result, loading:false});
 }
 useEffect(() => {
  if(generalFormRef?.current) generalFormRef.current.focus();
}, [generalFormRef]);
 
  return (
   <>
     <section ref={generalFormRef} className="flex justify-center flex-col lg:pb-32 lg:pt-6 md:pb-12 md:pt-4 sm:py-6">
     <h3 className="text-4xl font-bold text-white text-center mt-6 mb-12 leading-relaxed"> {pageTitle}</h3>
      <div className="grid lg:grid-cols-2 grid-cols-1 gap-3"> 
        <form onSubmit={handleSubmit}>
          <div className="mb-6">
              <label htmlFor="message" className="block mb-2 text-md font-medium text-cyan-500">{label2}</label>
              <textarea id="message" minLength={minLength} value={message} onChange={handleChange} name="message" rows="6" className="block p-2.5 w-full text-sm rounded-lg border focus:outline-none bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-cyan-500 focus:border-cyan-500" placeholder={label2}></textarea>
              <p className="mt-2 text-sm text-gray-400">{t("generalForm.p1")}</p>
              <p className="mt-2 text-sm text-gray-400">{t("generalForm.p2")}</p>
          </div>
          <Button loading={loading}/>
        </form>
        <Content generatedText={generatedText} loading={loading} />
      </div>
     </section>
   </>
  )
})
GeneralForm.propTypes = {
  message:PropTypes.string,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
}
export default GeneralForm