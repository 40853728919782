import Header from "./components/Layouts/Header";
import Footer from "./components/Layouts/Footer";
import About from "./components/AdditionalPages/About";
import Contact from "./components/AdditionalPages/Contact";
import Privacy from "./components/AdditionalPages/Privacy";
import NotFound from "./components/Layouts/NotFound";
import Home from "./pages/Home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import MathForm from "./pages/MathForm";
import BooksResume from "./pages/BooksResume";
import Science from "./pages/Science";
import Art from "./pages/Art";
import Literature from "./pages/Literature";
import GeneralWilling from "./pages/GeneralWilling";
import Physics from "./pages/Physics";
import History from "./pages/History";
import Essay from "./pages/Essay";
import packageJson from '../package.json';

import ReactGA from 'react-ga';
import { useEffect } from "react";
// import Blog1 from "./blogs/Blog1";
// import BlogList from "./blogs/BlogList";
// import Blog2 from "./blogs/Blog2";
// import Blog3 from "./blogs/Blog3";
// import Blog4 from "./blogs/Blog4";
// import Blog5 from "./blogs/Blog5";
// import Blog6 from "./blogs/Blog6";
// import Blog7 from "./blogs/Blog7";
// import Blog8 from "./blogs/Blog8";
// import Blog9 from "./blogs/Blog9";
// import Blog10 from "./blogs/Blog10";
// import Blog11 from "./blogs/Blog11";
// import Blog12 from "./blogs/Blog12";
// import Blog13 from "./blogs/Blog13";
// import Blog14 from "./blogs/Blog14";
// import Blog15 from "./blogs/Blog15";
// import Blog16 from "./blogs/Blog16";
// import Blog17 from "./blogs/Blog17";
// import Blog18 from "./blogs/Blog18";
// import Blog19 from "./blogs/Blog19";
// import Blog20 from "./blogs/Blog20";
const TRACKING_ID = "G-3WFK28MVS9"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  useEffect(() => {
    const checkAndUpdateCache = () => {
      let version = localStorage.getItem('version_thehomework');
      if (version !== packageJson.version) {
        if ('caches' in window) {
          caches.keys().then((names) => {
            // Delete all the cache files
            names.forEach((name) => {
              caches.delete(name);
            });
          });

          // Makes sure the page reloads. Changes are only visible after you refresh.
          window.location.reload(true);
        }

        localStorage.clear();
        localStorage.setItem('version_thehomework', packageJson.version);
      }
    };

    checkAndUpdateCache();
  }, []); // Pass an empty dependency array
  return (
    <>
      <Router>
        <Header />
        <main className="flex min-h-screen font-nunito bg-black">
          <div className="container mx-auto px-12 sm:px-4 flex-1">
            <Routes>
              <Route path="/" element={<Home />} />
              {/* <Route path="/blogs" element={<BlogList />} /> */}
              {/* <Route path="/last-generation-technology" element={<Blog1 />} />
              <Route path="/artificial-intelligence" element={<Blog2 />} />
              <Route path="/quantum-computing" element={<Blog3 />} />
              <Route path="/blockchain" element={<Blog4 />} />
              <Route path="/5g" element={<Blog5 />} />
              <Route path="/virtual-reality" element={<Blog6 />} />
              <Route path="/robots-and-automation" element={<Blog7 />} />
              <Route path="/cybersecurity" element={<Blog8 />} />
              <Route path="/nanotechnology" element={<Blog9 />} />
              <Route path="/autonomous-vehicles" element={<Blog10 />} />
              <Route path="/augmented-reality" element={<Blog11 />} />
              <Route path="/biotechnology" element={<Blog12 />} />
              <Route path="/smart-homes-and-cities" element={<Blog13 />} />
              <Route path="/renewable-energy-technologies" element={<Blog14 />} />
              <Route path="/the-internet-of-things" element={<Blog15 />} />
              <Route path="/virtual-reality-headset" element={<Blog16 />} />
              <Route path="/3d-printing" element={<Blog17 />} />
              <Route path="/drones" element={<Blog18 />} />
              <Route path="/smart-glasses" element={<Blog19 />} />
              <Route path="/smart-watches" element={<Blog20 />} /> */}
              <Route path="/about-us" element={<About />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/privacy-policy" element={<Privacy />} />
              <Route path="/math" element={<MathForm />} />
              <Route path="/summary" element={<BooksResume />} />
              <Route path="/science" element={<Science />} />
              <Route path="/literature" element={<Literature />} />
              <Route path="/general-knowledge" element={<GeneralWilling />} />
              <Route path="/art" element={<Art />} />
              <Route path="/physics" element={<Physics />} />
              <Route path="/history" element={<History />} />
              <Route path="/essay-thesis" element={<Essay />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        </main>
        <Footer />
      </Router>
    </>
  );
}

export default App;
